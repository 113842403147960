<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>회원 정보</span>
          </div>
          <div class="inform">
            <div>회원님의 개인 정보 보호를 위해 비밀번호를 확인해 주세요.</div>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="password"
                        placeholder="비밀번호를 입력해 주세요."
                        v-model="pw"
                      />
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnNext">확인</button>
              <div class="induce">
                <span
                  >카카오, 네이버, 구글로 가입하신 후 비밀번호가 생각나지
                  않으신가요?</span
                >
                <button @click="fnPhoneAuthPage">휴대폰 인증하러 가기</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-info-password-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
