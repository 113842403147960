import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { dispatch } = useStore()
  const state = reactive({
    pw: ''
  })
  const fnNext = async () => {
    if (!isValid()) return
    const params = {}
    params.havePwYn = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
    params.pw = state.pw
    const res = await proxy.$MyPageSvc.postMyUserInfoDetail(params)
    if (!proxy.$Util.isEmpty(res.userInfo)) {
      await dispatch('user/updateDetailForm', { detailForm: res.userInfo })
      await router.replace({ path: '/my-info/view' })
    } else if (res.resultCode === '9999') {
      alert('비밀번호를 다시 확인해주세요.')
    }
  }
  const fnPhoneAuthPage = async () => {
    await router.replace({ path: '/my-info/phone-auth' })
  }
  const isValid = () => {
    if (!proxy.$Util.isValidPassword(state.pw)) {
      alert(
        '영문, 숫자, 특수문자를 모두 포함하여 8자~12자 이내로 입력해주세요.'
      )
      return false
    }
    return true
  }
  return { ...toRefs(state), fnNext, fnPhoneAuthPage }
}
